import classnames from 'classnames';
import { useEffect, useState } from 'react';
import type { ChangeEvent } from 'react';
import {
  IoEyeSharp,
  IoEyeOffSharp,
  IoInformationCircleOutline,
} from 'react-icons/io5';
import { InputLabel } from './InputLabel';
import { Tooltip } from '../common/Tooltip';
import type { FieldProps } from 'remix-validated-form/dist/index.js';

type InputProps = {
  name?: string;
  label: string | null;
  type: string;
  className?: string;
  autocomplete?: string;
  defaultValue?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  value?: string;
  tooltip?: string;
  error?: string | undefined;
  getInputProps?: FieldProps['getInputProps'];
  placeholder?: string;
};

export function BaseInput({
  name,
  label,
  type,
  className,
  autocomplete,
  defaultValue,
  onChange,
  value,
  tooltip,
  error,
  getInputProps,
  placeholder,
}: InputProps) {
  const [inputType, setInputType] = useState<string>(type);
  const [showPassword, setShowPassword] = useState<boolean>(false);

  useEffect(() => {
    if (type === 'password') {
      setInputType(showPassword ? 'text' : 'password');
    }
  }, [showPassword, type]);

  return (
    <label
      htmlFor={name}
      className={classnames(
        'w-full px-px',
        className,
        className?.includes('sm:w-') ? '' : 'sm:w-[384px]'
      )}
    >
      <div className="flex items-end">
        <InputLabel>{label}</InputLabel>
        {tooltip && (
          <Tooltip content={tooltip} childrenClassName="p-1">
            <IoInformationCircleOutline />
          </Tooltip>
        )}
      </div>
      <div className="relative">
        {type === 'password' && (
          <div className="absolute bottom-0 right-4 top-0 flex items-center justify-center">
            <button
              className="my-auto"
              type="button"
              role="switch"
              aria-checked={showPassword}
              onClick={() => setShowPassword((prev) => !prev)}
              tabIndex={-1}
            >
              {showPassword ? (
                <IoEyeSharp className="text-xl text-gray-600" />
              ) : (
                <IoEyeOffSharp className="text-xl text-gray-600" />
              )}
            </button>
          </div>
        )}
        <input
          type={inputType}
          className={classnames(
            `w-full rounded-xl py-3 pl-5 ${
              type === 'password' ? 'pr-12' : 'pr-5'
            } border-gray-500`,
            type === 'file' && 'cursor-pointer border hover:bg-gray-50'
          )}
          autoComplete={autocomplete}
          defaultValue={defaultValue || undefined}
          onChange={onChange}
          value={value}
          {...(typeof getInputProps === 'function'
            ? { ...getInputProps({ id: name }) }
            : {})}
          placeholder={placeholder}
        />
      </div>
      {!error && <p className="h-7"></p>}
      {error && (
        <p className="whitespace-wrap my-1 text-sm text-rose-600">{error}</p>
      )}
    </label>
  );
}
